body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.landing-page{
  margin-top: 4.3rem;
}

.right-tb{
  margin-top: 4.4rem;
}

h3{
  font-family: Montserrat;
}

p{
  font-family: Roboto;
}

.dict{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 1.2rem 4.6rem;
}

.dict .dicH{
  margin-top: 12px;
}


.m-10{
  margin-top: 3.2rem;
}

